import React, { useContext }  from 'react';
import LivePreview from './LivePreview'
import request from '../utils/request'
import Auth from '../utils/auth';
import {Button, Modal} from 'react-bootstrap'

import '../styles/Finished.css';
const URL = process.env.NODE_ENV === 'production' || process.env.REACT_APP_VARIABLE === 'docker' ? 
                '' : 'http://localhost:2999'
                
const Finished = (props) => {
  const [tiles, setTiles] = React.useState([])
  const [mosaic, setMosaic] = React.useState(null)
  const [tileCount, setTileCount] = React.useState(0)
  const { authUser } = useContext(Auth);
  const [modal, setModal] = React.useState(-1)

  React.useEffect(() => {
    if (authUser && props.match.params.mosaicId) {
        const options = {
            body: {id: props.match.params.mosaicId}
        }
        request('/api/mosaic/get', options).then(res => {
            console.log(res)
            setMosaic(res)
            
        }).catch(err => {
          console.log(err)

            alert("Error")
        })
    }
  }, [props.match.params.mosaicId, authUser]);
  
  
  React.useEffect(() => {
    if (mosaic) {

      function fetchFinished() {
        console.log("fecthinggg" + mosaic._id)
        fetch(URL + "/api/mosaic/getFinished?id=" + mosaic._id)
        .then(response => response.json())
        .then(data => {
          console.log(data)
          setTileCount(data.files.length)
          var tilesArray = new Array(mosaic.grid_width * mosaic.grid_height).fill(null)
          data.files.forEach(element => {
            var num = Number(element.substring(0, element.length - 4))
            tilesArray[num] = element
          });
          setTiles(tilesArray)
        })
      }

      fetchFinished()
      setInterval(fetchFinished, 10000)
    }
  }, [mosaic]);

  function squareClicked(i) {
    setModal(i)
    console.log(i)
  }

  function deleteImg(i) {
    console.log(i)

    const options = {
      method: 'DELETE'
    }
    request('/api/admin/deletetile?mosaicId=' + mosaic._id + '&tileId=' + i, options).then(res => {
        window.location.reload(false)
    }).catch(err => {
        console.log(err)
        alert("Error deleting")
        window.location.reload(false)
    })
  }


  if(!mosaic){
    return (
        <div className="Dashboard" style={{height:'100vh'}}>
           <h1 className="cardTitle">Loading</h1>
        </div>
    )
  }

  return (
    <>
      <div className="App">
        <h2 className="Completed">{mosaic.name}</h2>
        <h3 className="CompletedSubText">Completed: {tileCount}/{mosaic.grid_height * mosaic.grid_width} tiles.</h3>
        <LivePreview imgClicked={i => squareClicked(i)} mosaic_id={mosaic._id} tiles={tiles} tileWidth={mosaic.grid_width}/>
      </div>
      <Modal size="lg" show={modal === -1 ? false : true} onHide={() => setModal(-1)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Delete Image and free airplane seat for image {modal}?
          </Modal.Title>
        </Modal.Header>      
          <Modal.Footer>
            <Button onClick={() => deleteImg(modal)} size="lg" variant="danger">
              Delete
            </Button>
          </Modal.Footer>
      </Modal>


  </>
  );
}

export default Finished;
