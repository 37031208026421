import React, { useContext } from 'react';
import LivePreview from './LivePreview'
import '../styles/Finished.css';
import { Button } from 'react-bootstrap';
import Cookies from 'universal-cookie'
import { useHistory } from "react-router";
import Auth from '../utils/auth';
import { useTranslation } from 'react-i18next';

const cookies = new Cookies()

const URL = process.env.NODE_ENV === 'production' || process.env.REACT_APP_VARIABLE === 'docker' ?
  '' : 'http://localhost:2999'

const Finished = (props) => {
  const [tiles, setTiles] = React.useState([])
  const [isFinished, setFinished] = React.useState(false)
  const { setUser } = useContext(Auth);
  const history = useHistory()

  const { t } = useTranslation();

  function signOutRestart() {
    cookies.remove('sessionCookie', { path: '/' })
    setUser(null)
    history.push("/join/" + props.mosaic._id);
  }

  React.useEffect(() => {

    async function fetchFinished() {
      const response = await fetch(URL + "/api/mosaic/getFinished?id=" + props.mosaic._id)
      const body = await response.json()
      console.log(body)
      if (body.finished) {
        console.log("finished!!!")
        setFinished(true)
        return "finished"
      } else {
        console.log("Fetched:", body)
        var tilesArray = new Array(props.mosaic.grid_width * props.mosaic.grid_height).fill(null)
        body.files.forEach(element => {
          var num = Number(element.substring(0, element.length - 4))
          tilesArray[num] = element
        });
        setTiles(tilesArray)
      }
    }

    var intervalId
    fetchFinished().then(firstTry => {
      if (props.mosaic.live_update && firstTry !== "finished") {
        intervalId = setInterval(function () {
          fetchFinished().then(otherTry => {
            if (otherTry === "finished") {
              clearInterval(intervalId)
            }
          })
        }, 10000)
      }
    })

    return () => {
      clearInterval(intervalId)
    }
  }, [props.mosaic]);


  if (!props.mosaic) {
    return (
      <div className="Dashboard" style={{ height: '100vh' }}>
        <h1 className="cardTitle">Loading</h1>
      </div>
    )
  }

  return (
    <div className="App">
      <h2 className="Completed">{t('finished.congrats')}</h2>
      {props.mosaic.allow_restart ?
        <>
          <br />
          <h4>Want to start from the beginning to create another tile? Click to <Button variant='danger' onClick={signOutRestart}>Restart</Button> </h4>
        </>
        : null
      }
      <br />
      {props.mosaic.live_update ?
        <>
          <h3 className="CompletedSubText">{t('finished.viewLive')}</h3>
          {isFinished ?
            <img alt="finished mosaic" style={{ width: "90vw", marginBottom: "20px" }} src={URL + "/media/" + props.mosaic._id + "/finished.png"} />
            :
            <LivePreview imgClicked={function () { }} mosaic_id={props.mosaic._id} tiles={tiles} tileWidth={props.mosaic.grid_width} />
          }

        </> : null}
    </div>
  );
}

export default Finished;
