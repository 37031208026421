import React from 'react';
import '../styles/LivePreview.css';
const URL = process.env.NODE_ENV === 'production' || process.env.REACT_APP_VARIABLE === 'docker' ? 
                '' : 'http://localhost:2999'
                
const LivePreview = (props) => {
  return (
    <div className="LivePreview">
        {props.tiles.map((tile, i) => 
            <div key={i} onClick={() => {props.imgClicked(i)}} style={{display: 'inline-block', width: (90/props.tileWidth).toString() + 'vw', height: (90/props.tileWidth).toString() + 'vw'}}>
                {tile ? <img alt="tile"  style={{width: '100%', height: '100%', display: 'block'}}src={URL + "/media/" + props.mosaic_id + "/finished/" + tile}/> : null}
            </div>
        )}
    </div>
  );
}

export default LivePreview;
