import React, { useContext } from 'react'
import {Form, Col, Card, Jumbotron, Button, Dropdown, Modal} from 'react-bootstrap'

import {Link} from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import  "../styles/Dashboard.css"

import request from '../utils/request'
import fileUpload from '../utils/fileUpload'

import Auth from '../utils/auth';

const URL = process.env.NODE_ENV === 'production' || process.env.REACT_APP_VARIABLE === 'docker' ? 
                '' : 'http://localhost:2999'

const urlPrefix = process.env.NODE_ENV === 'production' || process.env.REACT_APP_VARIABLE === 'docker' ? 
'https://mosaic.odysseyteams.com' : 'http://localhost:3000'

const MosaicCard = ({mosaic}) =>{

  const image = React.useRef()
  const buildImage = React.useRef()

  const textArea = React.createRef()

  const [url, setUrl] = React.useState(URL + "/media/" + mosaic._id + "/original.png")
  const [buildUrl, setBuildUrl] = React.useState(URL + "/media/" + mosaic._id + "/finished.png")

  const [modal, setModal] = React.useState(false)
  const [load, setLoad] = React.useState(false)
  const [buildLoad, setBuildLoad] = React.useState(false)
  const [imageFile, setImage] = React.useState(false)
  const [name, setName] = React.useState(mosaic.name)
  const [errorText, setErrorText] = React.useState(false)

  const [disabled, setDisabled] = React.useState(false)


  const buildMosaic = () => {
    setModal('loading')
    setDisabled(true)
    const options = {
      body: {mosaicId: mosaic._id}
    }
    request('/api/admin/build-image', options).then(res => {
      setModal('successBuild')
      console.log(res)
    }).catch(err => {
      customAlert("There was an error while building the mosaic")
    }).finally(() => setDisabled(false))
  }

  const bundleMosaic = () => {
    setModal('bundling')
    setDisabled(true)
    const options = {
      body: {mosaicId: mosaic._id}
    }
    request('/api/admin/bundle', options)
  }

  const addImage = async event => {
    event.preventDefault()
    console.log(imageFile)
    setModal('uploading')
    setDisabled(true)

    const formData = new FormData()
    formData.append('mosaicImage', imageFile)
    formData.append('id', mosaic._id)
    const options = {
      body: formData   
    }
    fileUpload('/api/admin/upload-image', options).then(res => {
      setModal('success')
      console.log(res)
    }).catch(err => {
      switch (err) {
        case 101: 
          customAlert("The image you uploaded is not a PNG.")
          break;
        case 102: 
          customAlert("The dimmensions of the image you uploaded are not mathematical multiples of your specified grid dimmensions.")
          break;
        case 103: 
          customAlert("The dimmensions of the image you uploaded would create tiles that are not square. Check your math.")
          break;
        default:
          customAlert("There was an unkown error")
      }
    }).finally(() => setDisabled(false))
  }

  const customAlert = (incomingErrorText) => {
    setErrorText(incomingErrorText)
    setModal('failure')
  }
  const copyLink = (e) => {
    textArea.current.select()
    setModal('copied')
    document.execCommand("copy")

  }

  const deleteMosaic = (e) => {
    setDisabled(true)
    //allow for custom room names later
    const mosaicId = mosaic._id
    const options = {
      body: {mosaicId},
      method: 'DELETE'
    }

    console.log(options)

    request('/api/admin/delete', options).then(res => {
      setModal('delete')
    }).catch(err => {
      setErrorText('Error while deleting Mosaic')
      setModal('failure')
      console.log(err)
    }).finally(() => setDisabled(false))
  }

  React.useEffect(() => {
    if(image && image.complete)
      setLoad(true)
    if(buildImage && buildImage.complete)
      setBuildLoad(true)
  }, [image, buildImage])

  return(
    <>
      <Card className="mosaicCard">
        <Card.Header><h4>{mosaic.name}</h4></Card.Header>
        <Card.Body className="justify content-end">
          <textarea style={{height:"0px", width:"0px", opacity:0, position:"absolute", 
            zIndex: "-1"}}
            ref={textArea}
            value={urlPrefix + "/join/" + mosaic._id}
            readOnly
          />
          {load ? <h6>Original:</h6> : null}
          <div style={{margin: '10px auto', width:"220px",maxHeight:"160px", position:"center",textAlign:"center"}}>
            <a target="_blank" rel="noopener noreferrer" href={url}>
              <img src={url} ref={image} onLoad={() => setLoad(true)} alt='Mosaic' 
                  style={{maxWidth: "220px", maxHeight: "160px", display: load ? 'inline' : 'none'}}/>
            </a>
            {load ? null : 
              <Jumbotron>
                <Button variant="primary" onClick={() => {setModal('upload')}}>Add Image</Button>
              </Jumbotron>
            }
          </div>

          {buildLoad ? <h6>Finished:</h6> : null}
          <div style={{margin: '10px auto', width:"220px",maxHeight:"160px", position:"center",textAlign:"center",  display: buildLoad ? 'block' : 'none'}}>
            <a target="_blank" rel="noopener noreferrer" href={buildUrl}>
                <img src={buildUrl} ref={buildImage} onLoad={() => setBuildLoad(true)} alt='Mosaic' 
                    style={{maxWidth: "220px", maxHeight: "160px"}}/>
            </a>
          </div>

          <div className="text-center">
            <p style={{marginBottom:'5px'}}>Grid size: {mosaic.grid_height}x{mosaic.grid_width}={mosaic.grid_height*mosaic.grid_width}</p>
            <p style={{marginBottom:'5px'}}>Image size: {image.current ? image.current.naturalHeight + 'x' + image.current.naturalWidth : '?'}</p>
          </div>
          </Card.Body>
          {load ? <Card.Footer>

          <div className="d-flex justify-content-between">
            <Button variant="primary" onClick={copyLink}>Copy Link</Button> 
          
            <div style={{display: 'flex'}}>
            <Dropdown>

                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  Options
                </Dropdown.Toggle>

               <Dropdown.Menu>
               <Dropdown.Item target="_blank" href={"/dashboard/list/" + mosaic._id}>View emails and answers</Dropdown.Item>
                  <Dropdown.Item target="_blank" href={"/dashboard/live/" + mosaic._id}>View live mosaic</Dropdown.Item>
                  <Dropdown.Item onClick={buildMosaic}>Build Mosaic</Dropdown.Item>
                  <Dropdown.Item onClick={bundleMosaic}>Bundle</Dropdown.Item>
                  <Dropdown.Item target="_blank" href={URL + "/media/" + mosaic._id + "/bundle.zip"}>Go to Bundle</Dropdown.Item>
                  {buildLoad ? <Dropdown.Item target="_blank" href={URL + "/media/" + mosaic._id + "/finishedsmall.png"}>Go to Small Build</Dropdown.Item> : null}

                  <Dropdown.Item 
                    onClick={() => setModal('deleteAsk') || setName(mosaic.name)}
                    style={{backgroundColor:"#dc3545", color:"white"}}>Delete Mosaic
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          </Card.Footer>
          : null}

      </Card>

      <Modal size="lg" show={modal === 'upload'} onHide={() => setModal(false) || setName(mosaic.name)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Upload Mosaic Image
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={addImage} style={{ className: 'text-center', width: "95%" }}>
          <Modal.Body>
            <Form.Group as={Col} controlId="booth_file">
              <Form.File isValid onChange={e => setImage(e.target.files[0])}/>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button style={{ backgroundColor: '#007bff'}} size="lg" variant="dark" type="submit" disabled={disabled}>
              Upload Image
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal size="lg" show={modal === 'named'} onHide={() => setModal(false) || setName(mosaic.name)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Creating Named Booth 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>What would you like to name your new Booth?</h3>
          <input value={name} onChange={e => setName(e.target.value)} type='text'/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModal(false) || setName(mosaic.name)}>Cancel</Button>
          <Button>Start Booth</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modal === 'success'} onHide={() => {
        setModal(false)
        setUrl(URL + "/media/" + mosaic._id + "/original.png?updated=true")
      }} centered> 
        <Modal.Header closeButton>
          <Modal.Title>Image Uploaded!</Modal.Title>
        </Modal.Header>
      </Modal>

      <Modal show={modal === 'uploading'} onHide={null} centered> 
        <Modal.Header>
          <Modal.Title>Uploading Image...</Modal.Title>
        </Modal.Header>
      </Modal>

      <Modal show={modal === 'bundling'} onHide={() => {setDisabled(false); setModal(false)}} centered> 
        <Modal.Header closeButton>
          <Modal.Title>Building Slides and Bundling. This may take a while. You can close this popup in the mean time.</Modal.Title>
        </Modal.Header>
      </Modal>

      <Modal show={modal === 'successBuild'} onHide={() => {
        setModal(false)
        setBuildUrl(URL + "/media/" + mosaic._id + "/finished.png?updated=true")
      }} centered> 
        <Modal.Header closeButton>
          <Modal.Title>Mosaic Built!</Modal.Title>
        </Modal.Header>
      </Modal>

      <Modal show={modal === 'failure'} onHide={() => setModal(false)} centered> 
        <Modal.Header closeButton>
          <Modal.Title>Error: {errorText}</Modal.Title>
        </Modal.Header>
      </Modal>

      <Modal show={modal === 'copied'} onHide={() => setModal(false)} centered> 
        <Modal.Header closeButton>
          <Modal.Title>Invitation link copied!</Modal.Title>
        </Modal.Header>
      </Modal>

      <Modal show={modal === 'loading'} onHide={null} centered> 
        <Modal.Header>
          <Modal.Title>Loading... This may take a while</Modal.Title>
        </Modal.Header>
      </Modal>

      <Modal size="lg" show={modal === 'deleteAsk'} onHide={() => setModal(false) || setName(mosaic.name)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Delete {mosaic.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Are you sure you want to delete this mosaic? It will remove all objects, files, and users associated with it.</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModal(false) || setName(mosaic.name)}>Cancel</Button>
          <Button variant="danger" onClick={deleteMosaic}>Delete</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modal === 'delete'} onHide={() => window.location.reload(false)} centered> 
        <Modal.Header closeButton>
          <Modal.Title>Your Mosaic was deleted.</Modal.Title>
        </Modal.Header>
      </Modal>
    </>
  );
}

const Dashboard = (props) => {
  const { user } = useContext(Auth);
  const [mosaics, setMosaics] = React.useState(undefined)
  const history = useHistory()

  React.useEffect( () => {
    request('/api/admin/getAll', {method: 'GET'}).then(res => {
        setMosaics(res)
    })
  }, [history, user])
     
  if(!mosaics){
    return (
        <div className="Dashboard" style={{height:'100vh', backgroundColor: '#1E1E1E'}}>
           <h1 className="cardTitle">Loading</h1>
        </div>
    )
  }

  return (
    <div className="Dashboard" style={{backgroundColor: '#1E1E1E'}}>
      <h1 className="cardTitle">All Mosaics</h1>
      <div>
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
            {mosaics.map((mosaic, i) => <MosaicCard key={i} mosaic={mosaic} />)}
        </div>
        <div style={{display:'flex', justifyContent: 'space-evenly'}}>
          <Link style={{marginTop: '20px', marginBottom: '35px'}} to="/dashboard/newmosaic">
            <button className="createMosaicBtn" variant="primary">Create a New Mosaic</button>
          </Link>
        </div>
      </div>
    </div>
  )
}


export default Dashboard;