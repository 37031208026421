import React,{useContext} from 'react';
import '../styles/Splash.css';

import Auth from '../utils/auth';
import { Button } from 'react-bootstrap';

function Splash() {
  const { hadnleAdminSignOn } = useContext(Auth);

  return (
    <div className="App" style={{height:'100vh', backgroundColor: '#1E1E1E'}}>
      <h1 className="Logo">Team Mosaic™</h1>
      <h3 className="SubText">Administrator Dashboard Login</h3>
        <Button id="loginBtn" onClick={hadnleAdminSignOn}>Log In</Button>
    </div>
  );
}

export default Splash;
