import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .use(LanguageDetector)

  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          splash: {
            title: 'Welcome to Team Mosaic™',
            step1: 'Step 1',
            step1Desc: 'Enter the System',
            changeDeviceWarn: 'Note: DO NOT CHANGE DEVICES or clear cookies after you begin!',
            buttonTxt: 'Click Here to Begin'
          },
          airplane: {
            step2: 'Step 2',
            step2DescP1: 'Select',
            step2DescP2: 'BLUE square(s):',
            selected: 'Selected',
            buttonTxt: 'Click Here for Step 3',
            errorPt1: 'You must select ',
            errorPt2: ' square(s)!'
          },
          step3: {
            step3: 'Step 3',
            step3Desc: 'Recreating your Square(s)',
            originalImage: 'Original Image',
            ticks: '(Ruler ticks are added for precision)',
            yourVersion: 'Your version',
            backTxt: 'Back',
            stepTxt: 'Step',
            nextTxt: 'Next',
            followTxt: 'Follow the instructions below for this step.',
            stepAPreBold: 'If you received the Mosaic Supply Kit this step is not necessary. No supplies? Draw a PERFECT square on a white piece of paper',
            stepABold: '(Not Lined or colored)',
            stepAPostBold: 'the same size as the above Original Image. You can trace on your computer screen or phone screen for reference.',
            videoTxt: 'Watch a help video for step',
            stepB: 'Recreate the above Original Image on your perfect square. NOTE: For best results use the same type of art supplies as other members of your team.',
            stepC: 'Take a photo of your recreation with your phone from directly above. Your “masterpiece" should be in the middle of your photo with nothing cutoff.',
            stepDPt1: '- ON COMPUTER: Email the photo of your "masterpiece" to yourself as a .jpeg or .png. to prepare for upload. DO NOT AIRDROP.',
            stepDPt2: '- ON PHONE: If you are using your phone for the entire process this step is not necessary. Select “NEXT”',
            stepE: 'NOTE: Remember your photo must be a .jpeg or a .png file type.',
            uploadTxt: 'Upload your photo'
          },
          step4: {
            step4: 'Step 4',
            step4Desc: 'Upload the photo of masterpiece',
            originalImg: 'Original image for reference',
            selectTxt: 'Select Photo From Your Device',
            videoTxt: 'Watch a help video for adjusting your image',
            dragTxt: 'Drag and Move Tool',
            scaleTxt: 'Scale and Crop Tool',
            rotateTxt: 'Rotate tool',
            previewTxt: 'Preview',
            previewDesc: 'Does this image look like the original image at the top of this screen? If so, upload. If not, improve your art, upload new photo or scale again.',
            uploadTxt: 'Upload Image',
            warningBld: 'WARNING',
            warningTxt: 'NO GOING BACK after you select Upload Image.',
            uploadedTxt: 'Image Uploaded!'
          },
          finished: {
            congrats: 'Congratulations! You have completed your part of our collective masterpiece.',
            viewLive: 'Watch as your teammates upload their images.'
          }
        }
      },
      spa: {
        translation: {
          splash: {
            title: '¡Bienvenidos a Team Mosaic!™',
            step1: 'Paso 1',
            step1Desc: 'Ingresa al sistema',
            changeDeviceWarn: 'Nota: ¡NO CAMBIES DE DISPOSITIVO ni borres las cookies después de empezar!',
            buttonTxt: 'Haz clic aquí para empezar'
          },
          airplane: {
            step2: 'Paso 2',
            step2DescP1: 'Selecciona',
            step2DescP2: 'casilla(s) AZUL:',
            selected: 'Casilla(s) elegida',
            buttonTxt: 'Haz clic aquí para ir al Paso 3',
            errorPt1: '¡Debes seleccionar ',
            errorPt2: ' casilla(s)!'
          },
          step3: {
            step3: 'Paso 3',
            step3Desc: 'Recreando la casilla(s) elegida(s)',
            originalImage: 'Imagen original',
            ticks: '(Las marcas de la regla se agregan para mayor precisión)',
            yourVersion: 'Your version',
            backTxt: 'Atrás',
            stepTxt: 'Paso',
            nextTxt: 'Siguiente',
            followTxt: 'Para este paso, sigue las siguientes instrucciones.',
            stepAPreBold: 'Si recibiste el kit de suministros Mosaic este paso no es necesario. ¿No tienes suministros? Dibuja un cuadrado PERFECTO en un papel blanco',
            stepABold: '(Que no esté rayado ni coloreado)',
            stepAPostBold: 'del mismo tamaño que imagen original anterior. Puedes trazarlo en la pantalla de tu computadora o en la pantalla de tu teléfono como referencia.',
            videoTxt: 'Mira el video de ayuda para el paso',
            stepB: 'Recrea la imagen original de arriba en tu cuadrado perfecto. NOTA: Para obtener los mejores resultados, utiliza el mismo tipo de material artístico que los demás miembros de tu equipo.',
            stepC: 'Haz una foto de tu recreación con tu teléfono vista directamente desde arriba. Tu "obra maestra" debe estar en el centro de la foto sin que se corte nada.',
            stepDPt1: '- EN TU COMPUTADORA: Envíate por correo electrónico la foto de tu "obra maestra" en formato .jpeg o .png para que puedas cargarla. NO LA ENVÍES POR AIRDROP.',
            stepDPt2: '- EN TU TELÉFONO: Si utilizas tu teléfono para todo el proceso, este paso no es necesario. Selecciona “SIGUIENTE”',
            stepE: 'NOTA: Recuerda que tu foto debe estar en formato .jpeg o .png.',
            uploadTxt: 'Carga tu foto'
          },
          step4: {
            step4: 'Paso 4',
            step4Desc: 'Carga la foto de la obra maestra',
            originalImg: 'Imagen original como referencia',
            selectTxt: 'Selecciona la foto de tu dispositivo',
            videoTxt: 'Mira el video de ayuda para ajustar tu imagen',
            dragTxt: 'Herramienta Arrastrar y Mover',
            scaleTxt: 'Herramienta Escala y Recorte',
            rotateTxt: 'Herramienta Rotar',
            previewTxt: 'Vista previa',
            previewDesc: '¿Se parece esta imagen a la imagen original que aparece en la parte superior de esta pantalla? Si es así, cárgala. En caso contrario, mejora tu arte, carga una nueva foto o vuelve a escalar.',
            uploadTxt: 'Cargar imagen',
            warningBld: 'ATENCIÓN',
            warningTxt: 'NO PUEDES IR ATRÁS después de seleccionar Cargar Imagen.',
            uploadedTxt: '¡Imagen cargada!'
          },
          finished: {
            congrats: '¡Felicitaciones! Has completado tu parte de nuestra obra maestra colectiva.',
            viewLive: 'Observa el resultado a medida que tus colegas cargan sus imágenes.'
          }
        }
      }
    }
  });

export default i18n;