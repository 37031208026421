import React from 'react';
import { useHistory, useLocation } from 'react-router-dom'

import db, { GoogleSignOn } from './firebase'
import Cookies from 'universal-cookie'

import request from './request'

const Auth = React.createContext();
export default Auth;

const cookies = new Cookies()

export const AuthProvider = (props) => {

  const [user, setUser] = React.useState(null)
  const [authUser, setAuthUser] = React.useState(null)

  const history = useHistory()
  const location = useLocation()

  const handleSignUp = (companyId, email, question_answer) => {
    console.log(companyId)

    db.auth().signOut()
    db.auth().signInAnonymously().catch(function (error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      if (errorCode || errorMessage) {
        console.log(errorCode, errorMessage)
      }
    });

    db.auth().onAuthStateChanged(async function (user) {
      if (user) {
        // User is signed in.
        var isAnonymous = user.isAnonymous;
        if (isAnonymous) {
          const token = await db.auth().currentUser.getIdToken()
          if (token) {
            return serverSignUp(token, companyId, email, question_answer)
          }
        }
      } else {

      }
    });
  }

  const hadnleAdminSignOn = () => {
    db.auth().signInWithPopup(GoogleSignOn).then(result => {
      const email = result.user.email
      const adminEmails = ["jackrileysheridan@gmail.com",
        "jonas@odysseyteams.com",
        "frank@odysseyteams.com",
        "lain@odysseyteams.com",
        "allison@odysseyteams.com",
        "ross@odysseyteams.com",
        "bill@odysseyteams.com",
        "katie@odysseyteams.com",
        "cewalker983@gmail.com",
        "meade.d@gmail.com",
        "kim@odysseyteams.com",
        "taylor@odysseyteams.com",
        "todd@odysseyteams.com"]

      if (!email || !adminEmails.includes(email)) {
        alert("Error: not an admin account (local)")
        return undefined
      }

      return db.auth().currentUser.getIdToken()
    }).then(token => {
      if (token) {
        return serverAdminLogin(token)
      }
    }).catch(error => {
      console.log(error)
    })
  }

  const handleSignOut = React.useCallback(() => {
    console.log("signing out")
    setUser(null);
    cookies.remove('sessionCookie')
    history.push('/splash')
  }, [history])

  const handleSignOutAdmin = React.useCallback(() => {
    console.log("signing out")
    setAuthUser(null);
    cookies.remove('sessionCookie')
    history.push('/dashboard/login')
  }, [history])

  const serverLogin = React.useCallback(credential => {
    const body = { credential }
    request('/api/user/login', { body }, true).then(response => {
      const { body } = response
      console.log(body)
      setUser(body)
      history.push('/')
    }).catch(error => {
      //alert("User no longer exists.")
      console.log(error)
      console.log("signing out")
      request('/api/user/sessionLogout', { body }, true).then(response => {
        console.log("signed out")
        window.location.reload(true)
      }).catch(error => {
        console.log("error signing out")
      })
    })
  }, [history])

  const serverSignUp = React.useCallback((credential, companyId, email, question_answer) => {
    const body = { credential, companyId, email, question_answer }
    request('/api/user/signup', { body }, true).then(response => {
      const { body } = response
      setUser(body)
      if (location.pathname.startsWith('/join/') || location.pathname.startsWith('/question/')) {
        history.push('/')
      }
    }).catch(error => {
      console.log(error)
      alert("Sign Up Error")
      console.log("signing out")
      setUser(null);
      cookies.remove('sessionCookie')
      window.location.reload(false)
    })
  }, [history, location])

  const serverAdminLogin = React.useCallback(credential => {
    const body = { credential }
    console.log(body)
    request('/api/user/adminlogin', { body }, true).then(response => {
      const { body } = response
      setAuthUser(body)
      if (location.pathname === '/dashboard/login') {
        history.push('/dashboard')
      }
    }).catch(error => {
      console.log(error)
      alert("Error: not an admin account")
      request('/api/user/sessionLogout', { body }, true).then(response => {
        console.log("signed out")
        window.location.reload(true)
      }).catch(error => {
        console.log("error signing out")
      })
      handleSignOutAdmin()
    })
  }, [history, location, handleSignOutAdmin])

  React.useEffect(() => {
    if (cookies.get('sessionCookie')) {
      console.log('session cookie found')
      if (location.pathname.startsWith('/dashboard')) {
        serverAdminLogin(null)
      } else {
        serverLogin(null)
      }
    } else {
      if (location.pathname === '/dashboard') {
        console.log('Session cookie not found. Redirecting.')
        history.push('/dashboard/login')
      } else if (location.pathname !== '/dashboard/login' && !location.pathname.startsWith('/join/') && !location.pathname.startsWith('/question/')) {
        console.log('Session cookie not found. Redirecting.')
        history.push('/splash')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const exportObj = {
    user, authUser, setUser,
    handleSignUp,
    handleSignOutAdmin,
    hadnleAdminSignOn,
    handleSignOut
  }

  return (
    <Auth.Provider value={exportObj}>
      {props.children}
    </Auth.Provider>
  )
}