import React,{useContext} from 'react';
import { Form, Button, Col} from 'react-bootstrap';
import  "../styles/NewMosaic.css"

import 'bootstrap/dist/css/bootstrap.min.css';
import request from '../utils/request'
import Auth from '../utils/auth';

const Question  = (props) =>  {
  const { handleSignUp, handleSignOut } = useContext(Auth);

  const [userStuff, setUserStuff] = React.useState({email: '', question_answer: ''})
  const [mosaic, setMosaic] = React.useState(null)
  const [disabled, setDisabled] = React.useState(false)


  React.useEffect( () => {
    if (props.match.params.companyId) {
      const options = {
        body: {id: props.match.params.companyId}
      }
      request('/api/mosaic/get', options).then(res => {
          console.log("did request")
          setMosaic(res)
          console.log(res)
      }).catch(err => {
        alert("Failed to find a mosaic with that ID")
        handleSignOut()
      })
    }
  }, [props.match.params.companyId, handleSignOut])

  
  const handleSubmit = async event => {
    console.log("submitted")

    event.preventDefault()

    setDisabled(true)
    const passEmail = mosaic.ask_email ? userStuff.email : undefined
    const passAnswer = mosaic.custom_question ? userStuff.question_answer : undefined
    handleSignUp(mosaic._id, passEmail, passAnswer)
  }

  return (
    
    <div>
      <br></br>
      <h1 style={{textAlign: 'center'}}>Step 1</h1>
      <Form onSubmit={handleSubmit} style={{ maxWidth: "95%" }}>
        {mosaic && mosaic.ask_email ? 
        <Form.Row>
          <Form.Group as={Col} controlId="name">

            <Form.Label>Email</Form.Label>
            <Form.Control size="lg" min="0" type="email" placeholder="Email" 
                          value={userStuff.email} onChange={(e) => setUserStuff({email: e.target.value, question_answer: userStuff.question_answer})}/>
          </Form.Group>
        </Form.Row> : null } 
        {mosaic && mosaic.custom_question ? 

        <Form.Row>
          <Form.Group as={Col} controlId="name">

            <Form.Label>{mosaic.custom_question}</Form.Label>
            <Form.Control size="lg" min="0" type="text" placeholder="Answer" 
                          value={userStuff.question_answer} onChange={(e) => setUserStuff({email: userStuff.email, question_answer: e.target.value})}/>
          </Form.Group>
        </Form.Row> : null } 
        
          <br />

        <div style={{display:'flex', justifyContent: 'space-evenly'}}>
          <Button style={{ backgroundColor: '#007bff'}} size="lg" variant="dark" type="submit" disabled={disabled}>
            Click here to continue to step 2
          </Button>
        </div>
      </Form>
    </div >
  )
}

export default Question;