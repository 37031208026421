import React, { useContext } from 'react'
import request from '../utils/request'

import '../styles/App.css';
import Airplane from './Airplane';
import Auth from '../utils/auth';
import List from '../components/List'
import Finished from './Finished';

const URL = process.env.NODE_ENV === 'production' || process.env.REACT_APP_VARIABLE === 'docker' ? 
                '' : 'http://localhost:2999'

function App() {
    const { user, handleSignOut } = useContext(Auth);
    const [mosaic, setMosaic] = React.useState(null)
    const [finishedCount, setFinishedCount] = React.useState(0)

  
    React.useEffect( () => {
        if (user) {
            const options = {
                body: {id: user.mosaic}
            }
            request('/api/mosaic/get', options).then(res => {
                setMosaic(res)
            }).catch(err => {
                alert("Failed to find a mosaic with that ID")
                handleSignOut()
            })
        }
    }, [user, handleSignOut])

    const indexOfAll = (arr, val) => arr.reduce((acc, el, i) => (el === val ? [...acc, i] : acc), []);


    React.useEffect( () => {
        if (mosaic) {
            indexOfAll(mosaic.tiles, user._id).forEach(function(entry) {
                var img = new Image();
                img.onload = function(){
                    setFinishedCount(prevState => prevState + 1)
                }
                img.src = URL + "/media/" + mosaic._id + "/finished/" + entry + ".png";
            });
        }
    }, [mosaic, user])


    if(!mosaic){
      return (
          <div style={{height:'100vh', textAlign: 'center'}}>
             <h1>Loading...</h1>
          </div>
      )
    }


    return (
        (finishedCount === mosaic.max_per_user) ? <Finished user={user} mosaic={mosaic}/> : 
        ((mosaic.tiles.filter(function(x){ return x === user._id; }).length === mosaic.max_per_user) ? 
            <List user={user} mosaic={mosaic}/> : <Airplane user={user} mosaic={mosaic}/>)    
    )
}

export default App;
