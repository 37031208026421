import React, { useContext } from 'react';
import '../styles/Splash.css';
import { Button } from 'react-bootstrap'
import SplashPageBackground from '../assets/splashBack.jpeg'
import request from '../utils/request'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import Auth from '../utils/auth';

const Splash = (props) => {
  const { handleSignUp, handleSignOut } = useContext(Auth);
  const [mosaic, setMosaic] = React.useState(null)
  const [disabled, setDisabled] = React.useState(false)
  const history = useHistory()
  const { t, i18n } = useTranslation();

  function handleStart() {
    if (mosaic.ask_email || mosaic.custom_question) {
      history.push('/question/' + mosaic._id)
    } else {
      handleSignUp(mosaic._id, undefined, undefined)
    }
  }

  const lngs = {
    en: { nativeName: 'English' },
    spa: { nativeName: 'Español' }
  };

  React.useEffect(() => {
    if (props.match.params.companyId) {
      const options = {
        body: { id: props.match.params.companyId }
      }
      request('/api/mosaic/get', options).then(res => {
        console.log("did request")
        setMosaic(res)
        console.log(res)
      }).catch(err => {
        alert("Failed to find a mosaic with that ID")
        handleSignOut()
      })
    }
  }, [props.match.params.companyId, handleSignOut])

  if (props.match.params.companyId && !mosaic) {
    return (
      <div className="Dashboard" style={{ height: '100vh' }}>
        <h1 className="cardTitle">Loading</h1>
      </div>
    )
  }

  return (
    <div className="App" style={{ backgroundImage: `url(${SplashPageBackground})`, height: '100vh' }}>
      <div className="logoBox">
        <h1 className="Logo">{t('splash.title')}</h1>
        <br />
        <div>
          {Object.keys(lngs).map((lng) => (
            <button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
              {lngs[lng].nativeName}
            </button>
          ))}
        </div>
        <h3><span id="stepSpan">{t('splash.step1')}</span><span className="SubText">: {t('splash.step1Desc')}</span></h3>
        <h3><span className="emoji" role="img" aria-label="warning">⚠</span><span className="SubText"> {t('splash.changeDeviceWarn')} </span><span className="emoji" role="img" aria-label="warning">⚠️</span></h3>

        <Button onClick={() => { setDisabled(true); handleStart() }} disabled={disabled} id="loginBtn">{t('splash.buttonTxt')}</Button>
        <p className="copyright">Copyright © 2020 Odyssey Teams Inc.</p>
      </div>
    </div>
  );
}

export default Splash;
