import React from 'react'
import { Col, Button, Container, Row } from 'react-bootstrap'
import request from '../utils/request'
import "../styles/Dashboard.css"
import notAvailable from '../assets/notAvailable.png'
import { useTranslation } from 'react-i18next';

const Airplane = (props) => {
    const [tiles, setTiles] = React.useState(props.mosaic.tiles)
    const [selected, setSelected] = React.useState([])
    const { t } = useTranslation();


    const handleOnSubmit = () => {
        if (selected.length < props.mosaic.max_per_user) {
            alert(t('airplane.errorPt1') + props.mosaic.max_per_user + t('airplane.errorPt2'))
        } else {
            const options = {
                body: { id: props.mosaic._id, selected }
            }
            request('/api/mosaic/update-tiles', options).then(res => {
                window.location.reload(false)
            }).catch(err => {
                console.log(err)
                alert("Looks like someone took that square just now! Please try again.")
                window.location.reload(false)
            })
        }
    }

    const seatClicked = (seat) => {
        if (!tiles[seat] && tiles.filter(function (x) { return x === props.user._id; }).length < props.mosaic.max_per_user) {
            const newTiles = tiles
            newTiles[seat] = props.user._id
            setSelected([...selected, seat])
            setTiles(newTiles)
        } else if (tiles[seat] && tiles[seat].toString() === props.user._id.toString()) {
            setSelected(selected.filter(mySeats => mySeats !== seat))
            tiles[seat] = null
            setTiles(tiles)
        }
    }

    function RowsCols(gridProps) {
        const items = []
        for (let i = 0; i < props.mosaic.grid_height; i++) {
            const columns = []
            for (let j = 0; j < props.mosaic.grid_width; j++) {
                const ij = props.mosaic.grid_width * i + j
                columns.push(<Col key={j} style={{ width: "35px", height: "35px" }} >
                    <Button onClick={() => seatClicked(ij)}
                        style={{
                            position: "center", width: "30px", height: "30px",
                            backgroundColor: tiles[ij] ? (tiles[ij].toString() === props.user._id.toString() ? "lightGreen" : "gray") : "#007bff",
                            backgroundImage: (tiles[ij] && (tiles[ij].toString() !== props.user._id.toString())) ? `url(${notAvailable})` : null,
                            backgroundSize: 'cover'
                        }}>
                    </Button>
                </Col>)
            }
            items.push(<Row key={i} noGutters>{columns}</Row>)
        }
        return items
    }

    return (
        <div style={{ height: '100vh', backgroundColor: 'white', textAlign: 'center' }}>
            <br />
            <h3 style={{ margin: '20px' }}><span id="stepSpan">{t('airplane.step2')}</span>: {t('airplane.step2DescP1')} {props.mosaic.max_per_user} {t('airplane.step2DescP2')}</h3>

            <div style={{ width: "90vw", overflow: "auto", position: "center", margin: "auto" }}>
                <Container style={{ width: props.mosaic.grid_width * 40, maxWidth: props.mosaic.grid_width * 40, height: props.mosaic.grid_height * 35, maxHeight: props.mosaic.grid_height * 35 }}>
                    <RowsCols />
                </Container>
            </div>
            <h6>{t('airplane.selected')}: {selected.join()}</h6>

            <Button style={{ marginBottom: '50px', fontSize: '30px' }} onClick={handleOnSubmit}>{t('airplane.buttonTxt')}</Button>
            <br />
        </div>
    )
}

export default Airplane;
