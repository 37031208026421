import React from 'react'
import { Form, Button, Col, Modal} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import  "../styles/NewMosaic.css"

import 'bootstrap/dist/css/bootstrap.min.css';
import request from '../utils/request'

const NewMosaic  = () =>  {
  
  const [mosaic, setMosaic] = React.useState({name: '', participant_count: null, grid_width: null, grid_height: null, max_per_user: null, custom_question: '', live_update: true, ask_email: false, allow_restart: false})
  const [modal, setModal] = React.useState(false)
  const [disabled, setDisabled] = React.useState(false)

  const history = useHistory()

  const handleClose = () => {
    setModal(false)
    history.push('/dashboard')
  }

  const handleFailClose = () => {
    setModal(false)
    setDisabled(false)
  }  
  
  const handleSubmit = async event => {

    event.preventDefault()

    setDisabled(true)
    console.log(mosaic)
    const body = {mosaic}
    console.log(body)
    request('/api/admin/create', { body }).then(async response => {
        setModal('created')
        setMosaic(response)
    }).catch(e => {
    console.log(e)
    if (e === 11000) {
        setModal('failureDuplicate')
    } else {
        setModal('failure')
    }
    window.onbeforeunload = null
    })
  }

  return (
    
    <div>
      <br></br>
      <h1 style={{textAlign: 'center'}}>Creating Mosaic</h1>
      <Form onSubmit={handleSubmit} style={{ maxWidth: "95%" }}>
        <Form.Row>
          <Form.Group as={Col} controlId="name">
            <Form.Label>Mosaic Name</Form.Label>
            <Form.Control size="lg" min="0" type="name" placeholder="Mosaic Name" 
              value={mosaic.name} onChange={(e) => 
                setMosaic({
                  name: e.target.value,
                  participant_count: mosaic.participant_count,
                  grid_width: mosaic.grid_width,
                  grid_height: mosaic.grid_height,
                  max_per_user: mosaic.max_per_user,
                  custom_question: mosaic.custom_question,
                  live_update:  mosaic.live_update,
                  ask_email: mosaic.ask_email,
                  allow_restart: mosaic.allow_restart
                })}
              />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="participant_count">
            <Form.Label>Participant Count</Form.Label>
            <Form.Control size="lg" type="number" placeholder="Participant Count" 
                          value={mosaic.participant_count ? mosaic.participant_count : ''} onChange={e => {
                              console.log(e.target.value)
                              if (!e.target.value || e.target.value > 0) {
                                setMosaic({
                                  name: mosaic.name,
                                  participant_count: e.target.value,
                                  grid_width: mosaic.grid_width,
                                  grid_height: mosaic.grid_height,
                                  max_per_user: mosaic.max_per_user,
                                  custom_question: mosaic.custom_question,
                                  live_update:  mosaic.live_update,
                                  ask_email: mosaic.ask_email,
                                  allow_restart: mosaic.allow_restart
                                })
                              } else {
                                setMosaic({
                                  name: mosaic.name,
                                  participant_count: 1,
                                  grid_width: mosaic.grid_width,
                                  grid_height: mosaic.grid_height,
                                  max_per_user: mosaic.max_per_user,
                                  custom_question: mosaic.custom_question,
                                  live_update:  mosaic.live_update,
                                  ask_email: mosaic.ask_email,
                                  allow_restart: mosaic.allow_restart
                                })
                              }
                            }}/>
          </Form.Group>

          <Form.Group as={Col} controlId="max_per_user">
            <Form.Label>Squares per</Form.Label>
            <Form.Control size="lg" type="number" placeholder="Squares per" 
                          value={mosaic.max_per_user ? mosaic.max_per_user : ''} onChange={e => {
                              console.log(e.target.value)
                              if (!e.target.value || e.target.value > 0) {
                                setMosaic({
                                  name: mosaic.name,
                                  participant_count: mosaic.participant_count,
                                  grid_width: mosaic.grid_width,
                                  grid_height: mosaic.grid_height,
                                  max_per_user: e.target.value,
                                  custom_question: mosaic.custom_question,
                                  live_update:  mosaic.live_update,
                                  ask_email: mosaic.ask_email,
                                  allow_restart: mosaic.allow_restart
                                })
                              } else {
                                setMosaic({
                                  name: mosaic.name,
                                  participant_count: mosaic.participant_count,
                                  grid_width: mosaic.grid_width,
                                  grid_height: mosaic.grid_height,
                                  max_per_user: 1,
                                  custom_question: mosaic.custom_question,
                                  live_update:  mosaic.live_update,
                                  ask_email: mosaic.ask_email,
                                  allow_restart: mosaic.allow_restart
                                })
                              }
                            }}/>
          </Form.Group>

          <Form.Group  as={Col} controlId="grid_total">
              <Form.Label>Target Squares</Form.Label>
              <Form.Control size="lg" type="text" placeholder={(mosaic.participant_count && mosaic.max_per_user) ? mosaic.participant_count * mosaic.max_per_user : '?'} disabled />
            </Form.Group>
        </Form.Row>


        <Form.Row>
            <Form.Group as={Col} controlId="grid_width">
                <Form.Label>Grid Width</Form.Label>
                <Form.Control size="lg" type="number" placeholder="Grid Width" 
                            value={mosaic.grid_width ? mosaic.grid_width : ''} onChange={e => {
                                if (!e.target.value || e.target.value > 0) {
                                    setMosaic({
                                      name: mosaic.name,
                                      participant_count: mosaic.participant_count,
                                      grid_width: e.target.value,
                                      grid_height: mosaic.grid_height,
                                      max_per_user: mosaic.max_per_user,
                                      custom_question: mosaic.custom_question,
                                      live_update:  mosaic.live_update,
                                      ask_email: mosaic.ask_email,
                                      allow_restart: mosaic.allow_restart
                                    })
                                  } else {
                                    setMosaic({
                                      name: mosaic.name,
                                      participant_count: mosaic.participant_count,
                                      grid_width: 1,
                                      grid_height: mosaic.grid_height,
                                      max_per_user: mosaic.max_per_user,
                                      custom_question: mosaic.custom_question,
                                      live_update:  mosaic.live_update,
                                      ask_email: mosaic.ask_email,
                                      allow_restart: mosaic.allow_restart
                                    })
                                  }
                                }}/>
            </Form.Group>

            <Form.Group as={Col} controlId="grid_height">
                <Form.Label>Grid Height</Form.Label>
                <Form.Control size="lg" type="number" placeholder="Grid Height" 
                            value={mosaic.grid_height ? mosaic.grid_height : ''} onChange={e => {
                                console.log(e.target.value)
                                if (!e.target.value || e.target.value > 0) {
                                  setMosaic({
                                    name: mosaic.name,
                                    participant_count: mosaic.participant_count,
                                    grid_width: mosaic.grid_width,
                                    grid_height: e.target.value,
                                    max_per_user: mosaic.max_per_user,
                                    custom_question: mosaic.custom_question,
                                    live_update:  mosaic.live_update,
                                    ask_email: mosaic.ask_email,
                                    allow_restart: mosaic.allow_restart
                                  })
                                } else {
                                    setMosaic({
                                      name: mosaic.name,
                                      participant_count: mosaic.participant_count,
                                      grid_width: mosaic.grid_width,
                                      grid_height: 1,
                                      max_per_user: mosaic.max_per_user,
                                      custom_question: mosaic.custom_question,
                                      live_update:  mosaic.live_update,
                                      ask_email: mosaic.ask_email,
                                      allow_restart: mosaic.allow_restart
                                    })
                                  }
                                }}/>
            </Form.Group>

            <Form.Group  as={Col} controlId="grid_total">
              <Form.Label>Total Squares</Form.Label>
              <Form.Control size="lg" type="text" placeholder={(mosaic.grid_width && mosaic.grid_height) ? mosaic.grid_width * mosaic.grid_height : '?'} disabled />
            </Form.Group>

          </Form.Row>
          <Form.Row>
          <Form.Group as={Col} controlId="custom_question">
            <Form.Label>Custom Question: (Leave blank to not have one)</Form.Label>
            <Form.Control size="lg" min="0" type="text" placeholder="Custom Question" 
                          value={mosaic.custom_question} 
                          onChange={e => setMosaic({
                            name: mosaic.name,
                            participant_count: mosaic.participant_count,
                            grid_width: mosaic.grid_width,
                            grid_height: mosaic.grid_height,
                            max_per_user: mosaic.max_per_user,
                            custom_question: e.target.value,
                            live_update:  mosaic.live_update,
                            ask_email: mosaic.ask_email,
                            allow_restart: mosaic.allow_restart
                          })}
            />
          </Form.Group>
        </Form.Row>
          <Form.Row>
            <Form.Check 
              type="switch"
              id="custom-switch"
              label="Participants can see live mosaic after uploading"
              defaultChecked
              onChange={e => setMosaic({name: mosaic.name,
                participant_count: mosaic.participant_count,
                grid_width: mosaic.grid_width,
                grid_height: mosaic.grid_height,
                max_per_user: mosaic.max_per_user,
                custom_question: mosaic.custom_question,
                live_update:  e.target.checked,
                ask_email: mosaic.ask_email,
                allow_restart: mosaic.allow_restart
              })}
            />           
          </Form.Row>
          <Form.Row>
            <Form.Check 
              type="switch"
              id="custom-switch2"
              label="Ask participants for email"
              onChange={e => setMosaic({name: mosaic.name,
                participant_count: mosaic.participant_count,
                grid_width: mosaic.grid_width,
                grid_height: mosaic.grid_height,
                max_per_user: mosaic.max_per_user,
                custom_question: mosaic.custom_question,
                live_update: mosaic.live_update,
                ask_email: e.target.checked,
                allow_restart: mosaic.allow_restart
              })}
            />           
          </Form.Row>
          <Form.Row>
            <Form.Check 
              type="switch"
              id="custom-switch3"
              label="Add button on final page to restart from beginning"
              onChange={e => setMosaic({name: mosaic.name,
                participant_count: mosaic.participant_count,
                grid_width: mosaic.grid_width,
                grid_height: mosaic.grid_height,
                max_per_user: mosaic.max_per_user,
                custom_question: mosaic.custom_question,
                live_update: mosaic.live_update,
                ask_email: mosaic.ask_email,
                allow_restart: e.target.checked
              })}
            />           
          </Form.Row>
          
          <br />

        <div style={{display:'flex', justifyContent: 'space-evenly'}}>
          <Button style={{ backgroundColor: '#007bff'}} size="lg" variant="dark" type="submit" disabled={disabled}>
            Create Mosaic
          </Button>
        </div>
      </Form>

      <Modal show={modal === 'created'} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Mosaic successfully created</Modal.Title>
        </Modal.Header>
      </Modal>

      <Modal show={modal === 'failure'} onHide={handleFailClose} centered> 
        <Modal.Header closeButton>
          <Modal.Title>There was an error! Let's try that again.</Modal.Title>
        </Modal.Header>
      </Modal>

      <Modal show={modal === 'failureDuplicate'} onHide={handleFailClose} centered> 
        <Modal.Header closeButton>
          <Modal.Title>This mosaic name is already in use! Let's try that again.</Modal.Title>
        </Modal.Header>
      </Modal>

    </div >
  )
}

export default NewMosaic;