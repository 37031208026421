import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";

//import '../styles/App.css';

//import Navbar from '../components/NavigationBar'

import Splash from '../components/Splash';
import App from '../components/App';
import Dashboard from '../components/Dashboard';
import DashboardLogin from '../components/DashboardLogin';
import DashBoardLive from '../components/DashBoardLive';
import DashboardList from '../components/DashboardList';
import NewMosaic from '../components/NewMosaic';
import NavigationBar from '../components/NavigationBar'
import Question from '../components/Question'

//import NotPage from './NotPage'
import { AuthProvider } from '../utils/auth';

const Router = () => {  

  return (
    <BrowserRouter>
      <AuthProvider>
      <NavigationBar/>
        <div className="app" id="main-component" >
          <Switch>
            <Route path="/" exact component={App}/>
            <Route path="/question/:companyId" component={Question}/>
            <Route path='/splash' component={() => { 
                window.location.href = 'https://odysseyteams.com/team-building-programs/virtual-team-mosaic/'; 
                return null;
            }}/>
            <Route path="/dashboard" exact component={Dashboard}/>
            <Route path="/dashboard/login" exact component={DashboardLogin}/>
            <Route path="/dashboard/newmosaic" exact component={NewMosaic}/>
            <Route path="/dashboard/live/:mosaicId" exact component={DashBoardLive}/>
            <Route path="/dashboard/list/:mosaicId" exact component={DashboardList}/>
            <Route path="/join/:companyId" component={Splash}/>

          </Switch>
        </div >
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Router;
