import React, { useContext } from 'react';
import { Navbar } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom';

import Auth from '../utils/auth';
import logout from '../assets/logout.png'

import '../styles/NavigationBar.css';

const NavigationBar = () => {
  const { authUser, handleSignOutAdmin } = useContext(Auth);
  const location = useLocation()

  //if (location.pathname === '/login') return <></>
  return (
    <div id="navbar-div" style={{ display: location.pathname === '/dashboard' ? 'block' : 'none',  
    height:'6vh', backgroundColor: "rgb(23, 40, 74)", zIndex: "2" }}>
      <Navbar className="navBar">
        <Navbar.Toggle />
        {
          authUser !== null && authUser !== undefined ?
            <>
              <div style={{
                width: '5vh',
              }}/>
              <div className="centered" style={{ textAlign: "center"}}>
                <Link to="/"><h1 className="NavBarLogo">Admin Dashboard</h1></Link>
              </div>
              <Navbar.Collapse className="justify-content-end">
                <div className="dropdown">
                  <button className="doorBtn"> <img src={logout} alt='Sign Out'></img></button>
                  <div className="dropdown-content">
                    <button className="signOutBtn"
                      onClick={handleSignOutAdmin}>Sign out</button>
                  </div>
                </div>
              </Navbar.Collapse>
            </>
            :
            <div className="centered">
                <Link to="/"><h1 className="NavBarLogo">Team Mosaic</h1></Link>
            </div>
        }
      </Navbar >
    </div >
  )
}

export default NavigationBar;