import React, { useContext }  from 'react';
import request from '../utils/request'
import Auth from '../utils/auth';
import {Table} from 'react-bootstrap'

import '../styles/Finished.css';
                
const Finished = (props) => {
  const [users, setUsers] = React.useState([])
  const [mosaic, setMosaic] = React.useState(null)
  const { authUser } = useContext(Auth);

  React.useEffect(() => {
    if (authUser && props.match.params.mosaicId) {
        const options = {
            body: {id: props.match.params.mosaicId}
        }
        request('/api/mosaic/get', options).then(res => {
            console.log(res)
            setMosaic(res)
            
        }).catch(err => {
          console.log(err)

            alert("Error")
        })
    }
  }, [props.match.params.mosaicId, authUser]);


  function getAllIndexes(arr, val) {
    var indexes = [], i;
    for(i = 0; i < arr.length; i++)
        if (arr[i] === val)
            indexes.push(i);
    return indexes;
  }

  React.useEffect(() => {
    if (mosaic) {

        const options = {
            method: 'GET'
        }
        request("/api/admin/getUsers?id=" + mosaic._id, options).then(res => {
            console.log(res)
            setUsers(res)
        }).catch(err => {
            console.log(err)
            alert("Error: ", err)
        })
       
    }
  }, [mosaic]);
  

  if(!mosaic){
    return (
        <div className="Dashboard" style={{height:'100vh'}}>
           <h1 className="cardTitle">Loading</h1>
        </div>
    )
  }

  return (
    <>
      <div className="App">
        <h2 className="Completed">{mosaic.name}</h2>
        <Table striped bordered hover>
        <thead>
            <tr>
            <th>#</th>
            <th>Email</th>
            <th>Question Answer</th>
            <th>Tiles Selected</th>
            </tr>
        </thead>
        <tbody>
            {users.map((user, i) =>
            <tr>
                <td>{i}</td>
                <td>{user.fake_email}</td>
                <td>{user.question_answer}</td>
            <td>{getAllIndexes(mosaic.tiles, user._id).join(', ')}</td>
            </tr>
            )}
        </tbody>
        </Table>      
        </div>
  </>
  );
}

export default Finished;
