import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage"

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyA6v28GQRVLOh0Qs07hXJbvjpqaHJL1XVs",
    authDomain: "odyssey-mosaic.firebaseapp.com",
    databaseURL: "https://odyssey-mosaic.firebaseio.com",
    projectId: "odyssey-mosaic",
    storageBucket: "odyssey-mosaic.appspot.com",
    messagingSenderId: "776527430024",
    appId: "1:776527430024:web:66a05b6f65edc42d15fa3d"
  };
// Initialize Firebase
const db = firebase.initializeApp(firebaseConfig);
export default db;
export const storage = firebase.storage()
export const GoogleSignOn = new firebase.auth.GoogleAuthProvider()

GoogleSignOn.setCustomParameters({
  'prompt': 'select_account'
});
