import React from 'react';
import '../styles/App.css';
import cropTutorial from '../assets/crop.png'
import fileUpload from '../utils/fileUpload'
import AvatarEditor from 'react-avatar-editor'
import "../styles/List.css"
import { useTranslation } from 'react-i18next';


import { Form, Col, Card, Button, Modal } from 'react-bootstrap'
const URL = process.env.NODE_ENV === 'production' || process.env.REACT_APP_VARIABLE === 'docker' ?
    '' : 'http://localhost:2999'


const SquareCard = ({ square, mosaic, origHeight, origWidth }) => {
    const [modal, setModal] = React.useState(false)
    const [imageFile, setImage] = React.useState(false)
    const [disabled, setDisabled] = React.useState(false)
    const [zoom, setZoom] = React.useState(1)
    const [rotation, setRotation] = React.useState(180)
    const [previewImage, setPreviewImage] = React.useState(null)
    const avEditor = React.useRef(null);
    const url = URL + "/media/" + mosaic._id + "/finished/" + square + ".png"
    const [load, setLoad] = React.useState(false)
    const [currentStep, setCurrentStep] = React.useState(0);
    const [instClass, setInstClass] = React.useState("");

    const stepArray = ["A", "B", "C", "D", "E"]

    const { t } = useTranslation();


    React.useEffect(() => {
        if (imageFile && imageFile.name.toLowerCase().endsWith(".heic")) {
            alert("That is an HEIC file. This webapp only accepts PNG's and JPG's. You can convert your image or simply screenshot it to convert it.")
            setImage(null)
        }
    }, [imageFile])

    function mouseMove(event) {
        setPreviewImage(null)
    }

    const addImage = async event => {
        event.preventDefault()
        console.log(previewImage)
        setModal('uploading')
        setDisabled(true)


        var blobBin = atob(previewImage.split(',')[1]);
        var array = [];
        for (var i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
        }
        var mosaicFinished = new Blob([new Uint8Array(array)], { type: 'image/png' });


        const formData = new FormData()
        formData.append('mosaicFinished', mosaicFinished)
        formData.append('id', mosaic._id)
        formData.append('square', square)

        const options = {
            body: formData
        }
        fileUpload('/api/mosaic/upload-finished', options).then(res => {
            setModal('success')
        }).catch(err => {
            if (err.code === 101) {
                setModal('pngfailure')
            } else {
                setModal('failure')
            }
        }).finally(() => setDisabled(false))
    }


    return (
        <>
            <Card key={square} className="listCard">
                <Card.Header><h3><b>{t('step3.originalImage')} {square}</b></h3></Card.Header>
                <Card.Body>
                    <h5>{t('step3.originalImage')}:</h5>
                    {t('step3.ticks')}
                    <div className="topTickMarks"></div>
                    <div className="leftAndRightTickMarks">
                        <div className="leftTickMarks"></div>
                        <a className="originalImageTick" target="_blank" rel="noopener noreferrer" href={URL + "/media/" + mosaic._id + "/split/" + square + ".png"}>
                            <img className="originalImageTick" src={URL + "/media/" + mosaic._id + "/split/" + square + ".png"} alt='Original' />
                        </a>
                        <div className="rightTickMarks"></div>
                    </div>
                    <div className="botTickMarks"></div>

                    <br />
                    <h5 style={{ display: load ? 'block' : 'none' }}>{t('step3.yourVersion')}:</h5>
                    <a target="_blank" rel="noopener noreferrer" href={url}>
                        <img src={url} onLoad={() => setLoad(true)} alt='Your Upload'
                            style={{ maxWidth: "250px", display: load ? 'inline' : 'none' }} />
                    </a>
                    <Card.Title style={{ marginTop: '10px' }}>


                        <Button style={{ marginRight: "15px" }} disabled={currentStep === 0} variant="primary" onClick={() => { setCurrentStep(currentStep => currentStep - 1); setInstClass("slideInLeft") }}>{"< " + t('step3.backTxt')}</Button>

                        {load ? null : t('step3.stepTxt') + " 3-" + stepArray[currentStep]}

                        <Button style={{ marginLeft: "15px" }} disabled={currentStep === 4} variant="primary" onClick={() => { setCurrentStep(currentStep => currentStep + 1); setInstClass("slideInRight") }}>{t('step3.nextTxt') + " >"}</Button>

                        <p>{t('step3.followTxt')}</p>


                    </Card.Title>
                    {load ?
                        null :
                        <div className={instClass} onAnimationEnd={() => setInstClass("")}>
                            {currentStep === 0 ?
                                <>
                                    <p><b>A. </b>{t('step3.stepAPreBold')} <b>{t('step3.stepABold')}</b> {t('step3.stepAPostBold')}</p>
                                    <br />
                                    <a target="blank" href="https://youtu.be/o-AB4EjJjl0">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-file-play" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H4z" />
                                            <path d="M6 10.117V5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43z" />
                                        </svg>
                                        {t('step3.videoTxt')} A</a>
                                </>
                                : null}
                            {currentStep === 1 ?
                                <>
                                    <p>
                                        <b>B. </b>{t('step3.stepB')}</p>
                                    <br />
                                    <a target="blank" href="https://youtu.be/Ph5JUmL-ef4">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-file-play" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H4z" />
                                            <path d="M6 10.117V5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43z" />
                                        </svg>
                                        {t('step3.videoTxt')} B</a>
                                </>
                                : null}
                            {currentStep === 2 ?
                                <>
                                    <p>
                                        <b>C. </b>{t('step3.stepC')}</p>
                                    <br />
                                    <a target="blank" href="https://youtu.be/MPQfmUUXsaU">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-file-play" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H4z" />
                                            <path d="M6 10.117V5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43z" />
                                        </svg>
                                        {t('step3.videoTxt')} C</a>
                                </>
                                : null}
                            {currentStep === 3 ?
                                <>
                                    <p>
                                        <b>D. </b>{t('step3.stepDPt1')}</p>
                                    <p>{t('step3.stepDPt2')}</p>
                                    <br />
                                    <a target="blank" href="https://youtu.be/hhMwt61qkBk">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-file-play" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H4z" />
                                            <path d="M6 10.117V5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43z" />
                                        </svg>
                                        {t('step3.videoTxt')} D</a>
                                </>
                                : null}
                            {currentStep === 4 ?
                                <>
                                    <p>{t('step3.stepE')}</p>
                                    <p>
                                        <b>E.</b> <Button variant="primary" onClick={() => { setModal('upload') }}>{t('step3.uploadTxt')}</Button>
                                    </p>
                                    <br />
                                    <a target="blank" href="https://youtu.be/2WwgrUMSW6E">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-file-play" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H4z" />
                                            <path d="M6 10.117V5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43z" />
                                        </svg>
                                        {t('step3.videoTxt')} E</a>
                                </>
                                : null}
                            <br />

                            <br />

                        </div>
                    }
                </Card.Body>
            </Card>


            <Modal show={modal === 'failure'} onHide={() => setModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>There was an error when uploading. Please try again.</Modal.Title>
                </Modal.Header>
            </Modal>

            <Modal show={modal === 'pngfailure'} onHide={() => setModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>The image uploaded was not a png.</Modal.Title>
                </Modal.Header>
            </Modal>

            <Modal show={modal === 'success'} onHide={() => {
                window.location.reload();
            }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t('step4.uploadedTxt')}</Modal.Title>
                </Modal.Header>
            </Modal>

            <Modal show={modal === 'uploading'} onHide={null} centered>
                <Modal.Header>
                    <Modal.Title>Uploading Image...</Modal.Title>
                </Modal.Header>
            </Modal>

            <Modal size="lg" show={modal === 'upload'} onHide={() => setModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3><span id="stepSpan">{t('step4.step4')}</span>: {t('step4.step4Desc')}</h3>
                    </Modal.Title>
                </Modal.Header>

                <Form onSubmit={addImage} style={{ className: 'text-center', width: "95%" }}>
                    <Modal.Body style={{ textAlign: 'center' }}>
                        {t('step4.originalImg')}:
                        <br />
                        <a target="_blank" rel="noopener noreferrer" href={URL + "/media/" + mosaic._id + "/split/" + square + ".png"}>
                            <img src={URL + "/media/" + mosaic._id + "/split/" + square + ".png"} style={{ maxWidth: "250px", margin: '10px' }} alt='Original for Reference' />
                        </a>
                        <Form.Group as={Col} style={{ margin: '20px auto' }} controlId="booth_file">
                            <Form.File
                                accept="image/*"
                                label={t('step4.selectTxt')}
                                onChange={e => setImage(e.target.files[0])}
                                custom />
                        </Form.Group>
                        {imageFile ?
                            <>
                                <img src={cropTutorial} alt='Tutorial Tips' style={{ width: '90%', maxWidth: '600px' }} />
                                <br />
                                <a target="blank" href="https://youtu.be/0EuqpdmAwuw">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-file-play" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H4z" />
                                        <path d="M6 10.117V5.883a.5.5 0 0 1 .757-.429l3.528 2.117a.5.5 0 0 1 0 .858l-3.528 2.117a.5.5 0 0 1-.757-.43z" />
                                    </svg>
                                    {t('step4.videoTxt')}</a>
                                <br />
                                {t('step4.dragTxt')}:
                                <br />
                                <AvatarEditor style={{ margin: 'auto' }}
                                    ref={avEditor}
                                    image={imageFile}
                                    width={origWidth / mosaic.grid_width}
                                    height={origHeight / mosaic.grid_height}
                                    border={0}
                                    onMouseMove={mouseMove}
                                    scale={zoom}
                                    rotate={parseInt(rotation) < 180 ? parseInt(rotation) + 180 : parseInt(rotation) - 180}
                                />
                                <br />

                                {t('step4.scaleTxt')}:
                                <input className='slider' style={{ width: "100%" }} type="range" min="100" max="500" value={100 * zoom} onChange={(e) => { setZoom(e.target.value / 100); setPreviewImage(null) }} />
                                {t('step4.rotateTxt')}:
                                <input className='slider' style={{ width: "100%" }} type="range" min="0" max="359" value={rotation} onChange={(e) => { setRotation(e.target.value); setPreviewImage(null) }} />
                                <Button style={{ backgroundColor: '#007bff', margin: 'auto', marginBottom: "20px" }} size="lg" variant="dark" disabled={disabled} onClick={() => {
                                    if (avEditor) {
                                        const canvasScaled = avEditor.current.getImageScaledToCanvas().toDataURL()
                                        setPreviewImage(canvasScaled)
                                    }
                                }}>
                                    {t('step4.previewTxt')}
                                </Button>
                                <br />
                                {
                                    previewImage ?
                                        <>
                                            <img src={previewImage} style={{ width: '200px' }} alt='Preview' />
                                            <br />
                                            <p>{t('step4.previewDesc')}</p>
                                            <br />
                                            <Button style={{ backgroundColor: '#007bff', margin: '20px auto' }} size="lg" variant="dark" type="submit" disabled={disabled}>
                                                {t('step4.uploadTxt')}
                                            </Button>
                                            <p><span className="emoji" role="img" aria-label="warning">⚠</span><b>{t('step4.warningBld')}:</b> {t('step4.warningTxt')}</p>

                                        </>
                                        : null
                                }
                            </> :
                            null
                        }

                    </Modal.Body>
                </Form>
            </Modal>
        </>
    )
}



const List = (props) => {


    const selected = props.mosaic.tiles.reduce((a, e, i) => (e === props.user._id) ? a.concat(i) : a, [])
    const [imageHeight, setImageHeight] = React.useState(null)
    const [imageWidth, setImageWidth] = React.useState(null)
    const { t } = useTranslation();

    React.useEffect(() => {
        let mounted = true
        var img = new Image();
        img.onload = function () {
            if (mounted) {
                setImageHeight(img.naturalHeight)
                setImageWidth(img.naturalWidth)
            }
        };
        img.src = URL + "/media/" + props.mosaic._id + "/original.png";
        return function cleanup() {
            mounted = false
        }
    }, [props.mosaic])



    return (
        <div className="App">
            <h3 style={{ marginTop: '25px' }}><span id="stepSpan">{t('step3.step3')}</span>: {t('step3.step3Desc')}</h3>
            {selected.map((square, i) =>
                <SquareCard key={i} square={square} mosaic={props.mosaic} origHeight={imageHeight} origWidth={imageWidth} />
            )}
        </div>
    );
}

export default List;
